/* eslint-disable */

let menu = []
const role = JSON.parse(localStorage.getItem('KultiverAuthUser')).role

// switch case with role
switch (role) {
  case 'admin':
    menu = [
      {
        header: 'Menú',
      },
      {
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Catálogos',
        icon: 'FolderIcon',
        children: [
          {
            title: 'Productos',
            route: 'catalogs-products',
          },
          {
            title: 'Listas de precios',
            route: 'price-lists',
          },
        ],
      },
      {
        title: 'Contactos',
        icon: 'UsersIcon',
        children: [
          {
            title: 'Clientes',
            route: 'contacts-customers',
          },
        ],
      },
      {
        title: 'Operaciones',
        icon: 'BoxIcon',
        children: [
          /* {
            title: 'Cotizaciones',
            route: 'transactions-sales',
          }, */
          {
            title: 'Pedidos',
            route: 'transactions-customer-orders',
          },
        ],
      },
      /* {
        title: 'Accesos',
        icon: 'LockIcon',
        children: [
          {
            title: 'Usuarios',
            route: 'access-users',
          },
          {
            title: 'Roles',
            route: 'access-rols',
          },
        ],
      },
      {
        title: 'Configuración',
        icon: 'SettingsIcon',
        children: [
          {
            title: 'Organización',
            route: 'settings-organization',
          },
        ],
      }, */
    ]
  break
  case'vendor':
    menu = [
      {
        header: 'Menú',
      },
      {
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Catálogos',
        icon: 'FolderIcon',
        children: [
          {
            title: 'Productos',
            route: 'catalogs-products',
          },
        ],
      },
      {
        title: 'Contactos',
        icon: 'UsersIcon',
        children: [
          {
            title: 'Clientes',
            route: 'contacts-customers',
          },
        ],
      },
      {
        title: 'Operaciones',
        icon: 'BoxIcon',
        children: [
          {
            title: 'Pedidos',
            route: 'transactions-customer-orders',
          },
        ],
      },
    ]
  break
  default:
    menu = []
    break
}
        
export default menu
/* eslint-disable */
